import request from '@/utils/request';

// 作业详情
export const homeworkDetail = (params, course) => request.post(`study/${course}homework/detail`, params)

// 作业保存
export const homeworkSave = (params, course) => request.post(`study/${course}homework/save`, params)

// 作业删除
export const homeworkDel = (params, course) => request.post(`study/${course}homework/del`, params)

// 作业提交
export const homeworkSubmit = (params, course) => request.post(`study/${course}homework/submit`, params)

// 待批阅作业列表
export const homeworkMakingList = params => request.post(`study/homework/making/list`, params)

// 待批阅作业详情
export const homeworkMakingDetail = (params, course)  => request.post(`study/${course}homework/making/detail`, params)

// 提交批阅
export const homeworkMaking = (params, course)  => request.post(`study/${course}homework/making/submit`, params)

// 批阅 - 学员列表
export const homeworkMember = (params)  => request.post(`study/homework/studymember/detail`, params)
// 批阅 - 批阅通过类型 学员列表
export const getHomeworkMemberByType = (params)  => request.post(`study/homework/studymember/taskdetail`, params)