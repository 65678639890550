<template>
  <!-- 实操作业 -->
  <a-modal
    v-model:visible="visible"
    :width="800"
    centered
    destroyOnClose
    :maskClosable="false"
    :keyboard="false"
    :title="$t('Sys_Homework')"
    :bodyStyle="{ padding: '10px 20px' }"
    @cancel="modalCancel"
  >
    <a-spin :spinning="modalLoading">
      <h3>{{ homework.title }}</h3>
      <h5 class="border-title">{{ $t("homework.work_requirements") }}</h5>
      <!-- 作业要求 -->
      <div class="intro">
        <div class="intro-item" v-for="(item, i) in homework.introList" :key="i">{{ item }}</div>
      </div>
      <div class="attachments">
        <div
          class="attachments-item"
          v-for="item in homework.attachments || []"
          :key="item.documentId"
        >
          <p class="attachments-item-p" @click="previewDoc(item)">
            <template v-if="item.secretData">
              {{ htsySecret[item.secretData] }}
            </template>
            {{
              item.fileName +
              item.filePath.substr(item.filePath.lastIndexOf("."))
            }}
          </p>
          <DownloadOutlined
            class="down"
            @click="fileDownload(item.sourceFilePath, item.fileName)"
          />
        </div>
      </div>
      <h5 class="border-title">{{ $t("homework.homework_content") }}</h5>
      <!-- 作业内容 -->
      <div class="steps">
        <div class="steps-item" v-for="(item, index) in userSteps" :key="index">
          <div class="steps-item-head">
            <h6>{{ $t("homework.step", [index + 1]) }}</h6>
            <!-- 第 步 -->
          </div>
          <div class="steps-item-content" v-if="item.content">
            {{ item.content }}
          </div>
          <div class="attachments" v-if="item.attachments">
            <div
              class="attachments-item"
              v-for="(file, fileIndex) in item.attachments || []"
              :key="fileIndex"
            >
              <p class="attachments-item-p" @click="previewDoc(file)">
                <template v-if="file.secretData">
                  {{ htsySecret[file.secretData] }}
                </template>
                {{
                  file.fileName +
                  file.filePath.substr(file.filePath.lastIndexOf("."))
                }}
              </p>
              <DownloadOutlined
                class="down"
                @click="fileDownload(file.sourceFilePath, file.fileName)"
              />
            </div>
          </div>
        </div>
      </div>
      <h5 class="border-title">{{ $t("Pub_Lab_MarkingStatus") }}</h5>
      <!-- 批阅状态 -->
      <div class="check">
        <div
          class="check-item"
          :class="{ green: item.status == 1, red: item.status == 3 }"
          v-for="(item, index) in flows"
          :key="index"
        >
          <p>
            <span>{{ $t("Pub_Lab_MarkingStatus") }}：</span>
            <!-- 批阅状态： -->
            <i class="status">{{ statusEnum[item.status] }}</i>
          </p>
          <template v-if="item.status != 2">
            <p>
              <span>{{ $t("homework.review_time") }}：</span>
              <!-- 批阅时间： -->
              {{ dateFormat(item.createTime) }}
            </p>
            <p>
              <span>{{ $t("homework.reviewer") }}：</span>
              <!-- 批阅人 -->
              {{ item.realName }}
            </p>
            <p v-if="homework.maxScore > 0">
              <span>
                {{ $t("LB_Favorite_Score") }}：
                <!-- 评分 -->
              </span>
              {{ item.score }}
            </p>
            <p>
              <span>
                {{ $t("homework.comments") }}
                <!-- 评语 -->
              </span>
              {{ item.remark }}
            </p>
          </template>
        </div>
      </div>
      <a-form
        v-if="isSubmit"
        layout="horizontal"
        class="check-form"
        ref="checkFormRef"
        :model="formDataRef"
      >
        <a-row>
          <a-col :span="12">
            <a-form-item label="状态" name="status">
              <a-radio-group v-model:value="formDataRef.status">
                <a-radio :value="1">{{ $t("CM_Pass") }}</a-radio>
                <!-- 通过 -->
                <a-radio :value="3">{{ $t("XB_Unthread") }}</a-radio>
                <!-- 不通过 -->
              </a-radio-group>
            </a-form-item>
          </a-col>
          <a-col :span="12" v-if="homework.maxScore > 0">
            <!-- 分数 -->
            <a-form-item
              label="分数"
              name="score"
              :rules="{
                required: true,
                type: 'number',
                message: '请填写分数',
                trigger: 'change',
              }"
            >
              <!-- 请填写分数 -->
              <a-input-number
                v-model:value="formDataRef.score"
                :min="0"
                :max="homework.maxScore"
                :precision="1"
              />
              &nbsp;&nbsp;{{ $t("homework.full_score") }}：{{
                homework.maxScore
              }}
              <!-- 满分： -->
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="24">
            <a-form-item :label="$t('homework.comments')" name="remark">
              <!-- 评语 -->
              <a-textarea v-model:value="formDataRef.remark" :rows="3" />
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-spin>
    <template #footer>
      <a-button key="back" @click="modalCancel">{{ $t("CM_Cancel") }}</a-button>
      <!-- 取消 -->
      <a-button
        v-if="isSubmit"
        key="submit"
        type="primary"
        :loading="modalLoading"
        @click="modalOk"
      >
        {{ $t("CM_Submit") }}
        <!-- 提交 -->
      </a-button>
    </template>
  </a-modal>
  <!-- 资料查看 -->
  <docPreview
    :isVisible="previewFile.visible"
    :documentId="previewFile.documentId"
    :fileStatus="previewFile.fileStatus"
    :fileType="previewFile.fileType"
    :filePath="previewFile.filePath"
    :fileName="previewFile.fileName"
    :fileHome="previewFile.fileHome"
    @previewEnd="
      (e) => {
        previewFile.visible = e;
      }
    "
  />
</template>

<script>
import { useI18n } from "vue-i18n";
import { reactive, toRefs, ref, getCurrentInstance } from "vue";
import { dateFormat, fileDownload } from "@/utils/tools";
import { homeworkMakingDetail, homeworkMaking } from "@/api/homework";
import { htsySecret } from "@/utils/business";
import docPreview from "@/components/preview/index.vue";
export default {
  name: "homeworkCheck",
  components: {
    docPreview,
  },
  emits: ["updata:homework"],
  setup(props, { emit }) {
    const { t: $t } = useI18n();
    const { proxy } = getCurrentInstance();
    const state = reactive({
      visible: false,
      modalLoading: false,
      taskId: 0,
      detailId: 0,
      homework: {},
      userSteps: [],
      userId: 0,
      flows: [],
      courseApi: "",
      params: {},
      previewFile: {
        visible: false,
        documentId: 0,
        fileStatus: 0,
        fileType: 0,
        filePath: "",
        fileName: "",
        fileHome: "",
      },
      isSubmit: false,
    });

    const openModal = (data) => {
      state.visible = true;
      state.modalLoading = true;
      if (data.courseId) {
        state.courseApi = "course/";
        state.params = {
          taskId: data.taskId,
          courseId: data.courseId,
          coursePlusId: data.coursePlusId,
        };
      } else {
        state.courseApi = "";
        state.params = {
          taskId: data.taskId,
          detailId: data.detailId,
        };
      }
      state.params.userId = data.userId;
      state.isSubmit = data.status === 2;
      // state.taskId = data.taskId;
      // state.detailId = data.detailId;
      formDataRef.status = 3;
      formDataRef.score = 0;
      formDataRef.remark = "";
      homeworkMakingDetail(state.params, state.courseApi).then((res) => {
        state.homework = res.data;
        state.flows = res.data.flows || [];
        state.userSteps = res.data.homeworks || [];
        state.userId = res.data.userId;
        state.modalLoading = false;
        // if (res.data.waits == 0) {
        //   proxy.$message.success($t("homework.all_approvals_are_complete"));
        //   // 全部批阅完成！
        //   modalCancel();
        // }
      });
    };

    const down = (item) => {
      let filePath = item.filePath;
      if (item.fileType == 3 || item.fileType == 6) {
        fileDownload(filePath, item.fileName);
      } else {
        window.open(filePath);
      }
    };

    const statusEnum = {
      //   1: "提交",
      //   2: "批阅",
      //   3: "重新提交",
      //   4: "通过",
      //   5: "不通过",
      1: $t("CM_Pass"), // 通过
      2: $t("CM_Submit"), // 提交
      3: $t("XB_Unthread"), // 不通过
    };

    const formDataRef = reactive({
      status: 3,
      score: 0,
      remark: "",
    });

    const checkFormRef = ref(null);
    const modalOk = () => {
      checkFormRef.value.validate().then(() => {
        state.modalLoading = true;
        homeworkMaking(
          {
            ...state.params,
            // userId: state.userId,
            status: formDataRef.status,
            score: formDataRef.score,
            remark: formDataRef.remark,
          },
          state.courseApi
        ).then((res) => {
          homeworkMakingDetail(state.params, state.courseApi).then((res) => {
            state.modalLoading = false;
            proxy.$message.success($t("homework.successful_approval"));
            modalCancel()
            // if (res.data.waits == 0) {
            //   proxy.$message.success($t("homework.all_approvals_are_complete"));
            //   // 全部批阅完成！
            //   modalCancel();
            // } else {
            //   proxy.$message.success($t("homework.successful_approval"));
            //   // 批阅成功
            //   openModal({
            //     ...state.params,
            //   });
            // }
          });
        });
      });
    };

    const modalCancel = () => {
      state.visible = false;
      emit("updata:homework");
    };

    const noViewSuffix = ['xls', 'xlsx'];
    const previewDoc = (item) => {
      if (noViewSuffix.includes(item.suffix)) {
        return proxy.$message.warning($t('FileNotPreview', { type: item.suffix }));
      };
      state.previewFile.visible = true;
      state.previewFile.fileStatus = item.status;
      state.previewFile.documentId = item.documentId || item.fileId || 0;
      state.previewFile.fileType = item.fileType;
      state.previewFile.filePath = item.filePath;
      state.previewFile.fileName = item.fileName || "";
      state.previewFile.fileHome = item.home || "";
    };

    return {
      dateFormat,
      ...toRefs(state),
      openModal,
      down,
      statusEnum,
      formDataRef,
      checkFormRef,
      modalOk,
      modalCancel,
      htsySecret,
      fileDownload,
      noViewSuffix,
      previewDoc,
    };
  },
};
</script>

<style lang="less" scoped>
.border-title {
  margin: 10px 0;
  font-size: 14px;
  line-height: 20px;
  padding-left: 10px;
  position: relative;
  &::before {
    content: "";
    width: 3px;
    height: 14px;
    background-color: @color-theme;
    position: absolute;
    left: 0;
    top: 3px;
  }
}
.attachments {
  margin-top: 5px;
  &-item {
    padding: 0 10px;
    background-color: #f1f1f1;
    margin-bottom: 1px;
    .mixinFlex(space-between; center);
    p {
      margin: 0;
      width: 90%;
      .mixinEllipsis(30px);
    }
    &-p {
      cursor: pointer;
      &:hover {
        color: @color-theme;
      }
    }
    .down {
      &:hover {
        color: @color-theme;
      }
    }
  }
}
.steps {
  margin-top: 10px;
  &-item {
    padding: 10px;
    border: 1px dashed #d9d9d9;
    margin-bottom: 10px;
    &-head {
      .mixinFlex(space-between; center);
      line-height: 30px;
      h6 {
        margin: 0;
      }
    }
    &-content {
      .text {
        padding: 5px;
        margin: 8px 0 10px;
        background-color: #f1f1f1;
      }
    }
  }
}
.check {
  border-left: 1px solid @color-theme;
  margin-left: 20px;
  margin-bottom: 5px;
  &-item {
    padding-left: 10px;
    position: relative;
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
    .status {
      font-style: normal;
    }
    &::before {
      content: "";
      background-color: @color-theme;
      width: 5px;
      height: 5px;
      border-radius: 50%;
      position: absolute;
      top: 9px;
      left: -3px;
    }
    &.green {
      &::before {
        background-color: green;
      }
      .status {
        color: green;
      }
    }
    &.red {
      &::before {
        background-color: red;
      }
      .status {
        color: red;
      }
    }
    p {
      margin-bottom: 2px;
      .mixinFlex(flex-start);
      span {
        width: 75px;
      }
    }
  }
}
.check-form {
  padding-left: 10px;
  .ant-form-item {
    margin-bottom: 10px;
  }
}
</style>
