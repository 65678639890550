<template>
  <div class="component-wrap top-wrap">
    <div>
      <h3 class="name">{{ stateData.info.detailName }}</h3>
      <div class="source">{{ $t("homework.table.score") }}:{{ stateData.info.source }}</div>
      <div class="source">
        {{ $t("Pub_Tab_Time") }}:
        {{ dateFormat(stateData.info.startTime) }} ~ {{ dateFormat(stateData.info.endTime) }}
      </div>
    </div>
    <a-button type="primary" @click="back">
      {{ $t("go_back") }}
      <!-- 返回 -->
    </a-button>
  </div>
  <div class="component-wrap">
    <div class="component-tit">
      <!-- 学员列表 -->
      {{ $t("homework.StudentList") }}
    </div>
    <div class="filter-wrap">
      <!-- 状态 -->
      <ListFilter
        only
        :name="$t('CM_Status')"
        :dataSource="stateData.reviewStatus"
        @onChange="switchStatus"
      >
        <template v-slot:right>
          <div class="search">
            <a-input-search
              v-model:value="queryParam.userName"
              :placeholder="$t('LB_Coach_EnterName')"
              style="width: 220px"
              enter-button
              allow-clear
              @search="handleSearch(queryParam)"
            />
            <!-- 请输入名称 -->
          </div>
        </template>
      </ListFilter>
    </div>
    <div class="table-wrap">
      <a-table
        :columns="stateData.columns"
        :data-source="state.dataSource"
        rowKey="userId"
        :bordered="false"
        :pagination="pagination"
        :loading="state.loading"
        @change="handleTableChange"
      >
        <template #realName="{ text }">
          <OpenData type="userName" :openid="text" />
        </template>
        <template #department="{ text }">
          <OpenData type="departmentName" :openid="text" />
        </template>
        <template #status="{ text }">
          <span style="color: #44b44f" v-if="text == 1">{{
            $t("LB_Teacher_HavaPerusal")
          }}</span>
          <!-- 已批阅 -->
          <span style="color: #e8673e" v-else>{{
            $t("Pub_WithoutApproval")
          }}</span>
          <!-- 未批阅 -->
        </template>
        <template #time="{ text }">
          {{ dateFormat(text) }}
        </template>
        <template #action="{ record }">
          <a-button
            type="link"
            @click="jump(record)"
          >
            {{ record.status === 2 ? $t("homework.approval") : $t("cm_view") }}
            <!--批阅 查看-->
          </a-button>
        </template>
      </a-table>
    </div>
  </div>
  <homeworkCheck
    ref="homeworkCheckRef"
    @updata:homework="handleSearch(queryParam)"
  />
</template>

<script setup>
import { useI18n } from "vue-i18n";
import { reactive, toRefs, toRaw, ref } from "vue";
import { homeworkMember, getHomeworkMemberByType } from "@/api/homework";
import { useFetchData } from "@/utils/useFetchData";
import ListFilter from "@/components/new/filter/ListFilter.vue";
import OpenData from "@/components/OpenData.vue";
import homeworkCheck from "@/components/homework/check.vue";
import { dateFormat } from "@/utils/tools";
import { useRouter } from "vue-router";
import { getTaskType } from "@/utils/business";

const props = defineProps({
  detail: {
    type: Object,
    default: () => {},
  },
})
const { t: $t } = useI18n();
const router = useRouter();
const stateData = reactive({
  info: {},
  reviewStatus: [
    { id: 1, name: $t("LB_Teacher_HavaPerusal") }, // 已批阅
    { id: 2, name: $t("Pub_WithoutApproval") }, // 未批阅
  ],
  columns: [
    {
      title: $t("Pub_Lab_UserName"),
      // title: "姓名",
      dataIndex: "realName",
      ellipsis: true,
      width: 200,
      slots: { customRender: "realName" },
    },
    {
      title: $t("login.账号"),
      // title: "账号",
      dataIndex: "account",
      ellipsis: true,
      width: 150,
    },
    {
      title: $t("homework.Department"),
      // title: "所属部门",
      dataIndex: "departmentName",
      ellipsis: true,
      width: 200,
      slots: { customRender: "department" },
    },
    {
      title: $t("homework.table.state"),
      // title: "批阅状态",
      dataIndex: "status",
      width: 100,
      slots: { customRender: "status" },
    },
    {
      title: $t("homework.review_time"),
      // title: "批阅时间",
      dataIndex: "lastTime",
      width: 150,
      slots: { customRender: "time" },
    },
    {
      title: $t("homework.table.operate"),
      // title: "操作",
      dataIndex: "action",
      fixed: "right",
      align: "center",
      width: 100,
      slots: { customRender: "action" },
    },
  ],
});

function generateSource(record) {
  let str = '';
  if (record.courseId && record.taskId) {
    // 任务-课程-作业 
    str = `【${getTaskType(record.taskType)}】${record.taskName}-${record.courseName}`;    
  } else if (record.courseId) {
    // 课程-作业
    str = `【${$t("course.online_course")}】${record.courseName}`
  } else {
    // 任务-作业
    str = `【${getTaskType(record.taskType)}】 ${record.taskName}`
  }
  return str;
};

let method = props.detail.completeCondition === 3 ? homeworkMember : getHomeworkMemberByType;
let queryParam = reactive({
  userName: '',
  taskType: 2, // 1 任务 2 课程
});
const initQuery = () => {
  if (props.detail.taskId && props.detail.courseId) {
    // 任务 课程 作业
    queryParam.taskId = props.detail.taskId;
    queryParam.courseId = props.detail.courseId;
    queryParam.courseplusId = props.detail.coursePlusId;
  } else if (props.detail.courseId) {
    // 课程 作业
    queryParam.courseId = props.detail.courseId;
    queryParam.courseplusId = props.detail.coursePlusId;
  } else {
    // 任务作业
    queryParam.taskId = props.detail.taskId;
    queryParam.detailId = props.detail.detailId;
    queryParam.taskType = 1;
  }
};
initQuery();

const getList = (params) => {
  let form = JSON.parse(JSON.stringify(queryParam));
  form.page = params.current;
  form.pageSize = params.pageSize;
  return method(form).then((res) => {
    const homework = res.data.taskHomework[0]
    stateData.info = homework;
    stateData.info.source = generateSource(props.detail);
    return {
      success: true,
      data: res.data.list,
      current: res.data.page,
      pageSize: res.data.pageSize,
      total: res.data.totals,
    };
  });
};

const {
  stripe,
  reload,
  setPageInfo,
  context: state,
} = useFetchData(getList, {
  current: 1,
  pageSize: 10,
  tableSize: "middle", // 'default' | 'middle' | 'small'
  stripe: false,
});

const handleTableChange = ({ current, pageSize }, filters, sorter) => {
  setPageInfo({
    current,
    pageSize,
    sortKey: sorter.field,
    sort: sorter.order,
    ...filters,
  });

  reload();
};
const { total, pageSize, current } = toRefs(state);

const pagination = reactive({
  showSizeChanger: true,
  showQuickJumper: false,
  pageSizeOptions: ["10", "30", "50", "100"],
  current,
  pageSize,
  total,
  onChange: (page, pageSize) => {
    setPageInfo({ current: page, pageSize });
    reload();
  },
  onShowSizeChange: (current, size) => {
    setPageInfo({ current, pageSize: size });
    reload();
  },
});

const handleSearch = (searchModel) => {
  if (searchModel) {
    queryParam = searchModel;
  } else {
    queryParam.userName = "";
    delete queryParam.status;
  }
  setPageInfo({ current: 1, ...toRaw(queryParam) });
  reload();
};
const switchStatus = (arr) => {
  queryParam.status = arr[0];
  handleSearch(queryParam);
};

const emit = defineEmits(['back']);
function back() {
  emit("back");
}

const homeworkCheckRef = ref(null);
function jump(item) {
  if (props.detail.completeCondition === 2 || props.detail.courseId) {
    homeworkCheckRef.value.openModal(item);
  } else {
    router.push({
      path: '/questionnaire/homework',
      query: {
        id: item.taskId,
        did: item.detailId,
        userId: item.userId,
        questionnaireId: item.evaluationId,
        taskaskId: item.taskaskId,
      }
    })
  }
}

</script>

<style lang="less" scoped>
.component-wrap {
  // min-height: 100%;
  background: #fff;
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0px 0px 15px 0px rgba(148, 148, 148, 0.15);

  .component-tit {
    font-size: 20px;
    line-height: 26px;
    color: #333;
    font-weight: 600;
    padding: 0 0 12px;
  }

  .filter-wrap {
    padding-bottom: 12px;
    border-bottom: 1px solid #f4f4f4;

    .filter {
      padding: 5px 0;
    }

    .search {
      padding: 5px 0;
      .mixinFlex(flex-end);

      ::v-deep(.ant-input-search) {
        padding-right: 1px;
        border-radius: 4px 0 0 4px;
      }

      ::v-deep(.ant-btn) {
        border-radius: 0 4px 4px 0;
      }
    }
  }
}
.top-wrap {
  .mixinFlex(space-between, center);
  margin-bottom: 20px;
  color: #333;
  .name {
    font-size: 20px;
    font-weight: 600;
  }
  .source {
    font-size: 14px;
  }
}
</style>
